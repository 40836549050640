<template>
  <v-app>
    <v-app-bar color="primary" flat height="110">
      <v-spacer></v-spacer>
      <a href="#" class="text-center">
        <img src="/media/logos/logo_light_155x30.png" class="max-h-70px" alt />
      </a>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-content class="mb-12">
      <v-container class="mb-12">
        <v-container class="mb-12">
          <v-row justify="center" align="center">
            <v-col md="8" cols="12">
              <v-card flat>
                <v-card-title>
                  <v-spacer />
                  <div class="text-center">
                    <div class="font-size-h1 bold-text">
                      Thank you for downloading the TestApp.io App
                    </div>
                  </div>
                  <v-spacer />
                </v-card-title>
                <v-card-text class="text-center">
                  <div class="dark-grey-text bodyFont mb-4">
                    Download not working ?
                    <span
                      @click="downloadMobileApp"
                      class="primary--text bold-text cursor-pointer"
                      >Retry download.</span
                    >
                  </div>
                  <div class="my-4">
                    <v-divider></v-divider>
                  </div>
                  <div
                    class="text-center bold-text font-size-h3 dark-grey-text"
                  >
                    Steps to install
                  </div>
                  <div class="my-4">
                    <v-divider></v-divider>
                  </div>
                  <v-row align="center">
                    <v-col class="text-left font-size-h3 bold-text" cols="6">
                      1. Turn on unknown source
                    </v-col>
                    <v-col cols="6">
                      <v-img :src="images[0]"></v-img>
                    </v-col>
                  </v-row>
                  <div class="my-4">
                    <v-divider></v-divider>
                  </div>
                  <v-row align="center">
                    <v-col class="text-left font-size-h3 bold-text" cols="6">
                      2. Install App
                    </v-col>
                    <v-col cols="6">
                      <v-img :src="images[0]"></v-img>
                    </v-col>
                  </v-row>
                  <div class="my-4">
                    <v-divider></v-divider>
                  </div>
                  <v-row align="center">
                    <v-col class="text-left font-size-h3 bold-text" cols="6">
                      3. Open App
                    </v-col>
                    <v-col cols="6">
                      <v-img :src="images[0]"></v-img>
                    </v-col>
                  </v-row>
                  <div class="my-4">
                    <v-divider></v-divider>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-content>
    <v-footer>
      <k-t-footer></k-t-footer>
    </v-footer>
  </v-app>
</template>

<style lang="scss">
.v-btn.install {
  width: 195px !important;
  height: 64px !important;
  border-radius: 10px !important;
  span {
    font-size: 19px !important;
    text-transform: capitalize;
    letter-spacing: 0;
  }
}
img.install-img {
  width: 195px !important;
  height: 64px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTFooter from "@/view/layout/footer/Footer.vue";

export default {
  name: "install",
  components: { KTFooter },
  data() {
    return {
      mobile: "",
      message: "",
      images: [
        "https://testapp.io/images/TestApp.io---App.svg",
        "https://testapp.io/images/TestApp.io---Chat.png",
        "https://testapp.io/images/TestApp.io---Notifications.png",
      ],
      secondaryMessage: "",
      showAndroidOption: false,
      showIOSOption: false,
      selectedLink: "",
      selectedTitle: "",
      showDownloadModal: false,
      downloadLinks: {
        playStore: {
          link: "https://play.google.com/store/apps/details?id=testapp.io&hl=en_US&gl=US",
          title: "Google Play Store",
        },
        huaweiStore: {
          link: "https://appgallery.huawei.com/#/app/C103407753",
          title: "Huawei AppGallery",
        },
        samsungStore: {
          link: "https://galaxy.store/testappio",
          title: "Samsung Galaxy Store",
        },
        amazonStore: {
          link: "https://www.amazon.com/gp/product/B095RFPRNR",
          title: "Amazon AppStore",
        },
        appStore: {
          link: "https://apps.apple.com/us/app/testapp-io/id1518972541",
          title: "Apple App Store",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getActivateErrorMessage: "getActivateErrorMessage",
    }),
  },
  created() {
    if (this.$route.params.message) {
      this.message = this.$route.params.message;
    }
    this.mobile = this.getMobileOperatingSystem;

    if (this.mobile === "unknown") {
      this.secondaryMessage =
        "To create a release go to developer portal and to install a release download our app.";
    } else {
      this.secondaryMessage =
        "Open or install the app form below button to get started.";
    }
  },
  methods: {
    openDownloadConfirm() {
      alert(this.mobile);
      if (this.mobile !== "android") {
        this.showDownloadConfirm = true;
      }
    },
    downloadMobileApp() {
      window.open(
        "https://github.com/testappio/android/releases/download/1.9/testapp.io_v1.9.apk",
        "_blank"
      );
    },
  },
};
</script>
